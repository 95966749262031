import './app.css'

import React, { useState } from 'react';

import Header from './header/Header';
import Mail from '../postMail/postMail'
import Slide from '../slider/Slider'
import { useEffect } from 'react';

const App = props => {
    const [userSettings, setUserSettings] = useState("")

    useEffect(() => {

        const parsedSettings = JSON.parse(localStorage.getItem("userSettings"))
        setUserSettings(parsedSettings)

    }, [])



    const getMail = event => {
        setUserSettings(event)
        localStorage.setItem("userSettings", JSON.stringify(event))
    }

    return (
        <div>

            {userSettings ? (
                <div>
                    <Header hashtag={userSettings} />
                    <Slide mail={userSettings} />
                </div>
            ) : (
                    <div className='background'>
                        <Header hashtag={userSettings} />
                        <Mail sendMail={getMail} />
                    </div>
                )
            }
        </div>
    );
};

export default App;