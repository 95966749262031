import "./header.css";

import { Box, Heading, Image } from 'grommet';

import React from "react";
import logo from "../image/hipcoo_logo.png";

const Header = () => (
  <Box
    tag='header'
    pad='small'
    elevation='small'
    justify='between'
    direction='row'
    align='center'
    flex={false}
  >
    <a href="./" onClick={()=> {localStorage.clear()}}><Image src={logo} className="logo" /></a>
    <Heading level={3} margin='none' color="white" size="7vh">
      <strong>HIPCOO</strong>
    </Heading>

  </Box>
);

export default Header;
