import "./Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Avatar, Image } from "grommet";
import React, { Component } from "react";

import Slider from "react-slick";
import axios from "axios";

const config = require("../config/config");
const backEnd = config.config.BACKEND;

class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
      loading: "Chargement des Tweets ...",
    };
  }

  componentDidMount() {
    this.axiosPost();
    var test = JSON.parse(localStorage.getItem("tweetData"))
    if (test !== null){
    test = JSON.parse(test.data)
    this.setState({image:test.data})
    }
    
    
    this.timer = setInterval(() => {
      this.axiosPost();
    }, 300000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  axiosPost() {
    axios
      .post(
        backEnd + this.props.mail.hashtag,
        {
          mail: this.props.mail.email,
          hashtag: this.props.mail.hashtag,
        },
        { timout: 30000 }
      )
      .then(
        function (response) {
          localStorage.setItem("tweetData", JSON.stringify(response));  
          var tweets = JSON.parse(localStorage.getItem("tweetData"))
          tweets = JSON.parse(tweets.data)
          this.setState({image : tweets.data})
        

        }.bind(this)
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      adaptiveHeight: true,
      arrows: false,
    };
    if (this.state.image.length !== 0) {
      return (
        <div>
          <Slider {...settings}>
            {this.state.image.map((i) => (
              <div key={i}>
                <div className="flex">
                  <Image src={i.mediaHipcoo} className="image" alt={i} />
                    <div className="flex2">
                      <div>
                        <Avatar className="avatar" size="7vh" src={i.userLogo} />
                        <p className="userName">{i.userName}</p>
                      </div>
                      <p className="text">{i.text}</p>
                    </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      );
    } else {
      return (
        <div>
          <p>{this.state.loading}</p>
        </div>
      );
    }
  }
}

export default Slide;
