import "./postMail.css"

import { Box, Button, Grommet, TextInput } from 'grommet'
import React, { useEffect } from "react";

import Theme from '../hipcooWebsite/Groomet'
import { useForm } from "react-hook-form";

const Mail = props => {
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = event => {
    props.sendMail(event);
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grommet className="fieldMail" theme={Theme} >
        <TextInput
          placeholder="Email"
          name="email"
          style={{ marginBottom: 6, borderColor: 'white' , color: "white" }}
          size="large"
          
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address"
            }
          })}
        />

        {errors.email && errors.email.message}
        <TextInput
          size="large"
          placeholder="Hashtag"
          name="hashtag"
          style={{borderColor: 'white', color: "white" }}
          ref={register({
            required: "Required",
          })}
        />
        <Box direction="row" gap="medium">
          <Button className="button" type="submit" alignSelf="stretch" size="large" primary label="Envoyer" color="#17242A" style={{ marginTop: 10, width: 1000 }} />
        </Box>
      </Grommet>
    </form>
  );
};

export default Mail;